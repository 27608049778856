<ngx-loading [show]="loading" [config]=""></ngx-loading>
<h2 matDialogTitle>
  Editace
</h2>
<div mat-dialog-content>
<mat-form-field class="fill-width" appearance="outline">
  <mat-label>Název místa</mat-label>
  <input matInput [(ngModel)]="currentData.title" type="text" #titleInput/>
</mat-form-field>
<mat-form-field class="fill-width" appearance="outline">
  <input matInput [(ngModel)]="currentData.kraj" type="text" placeholder="Kraj" [matAutocomplete]="auto">
  <mat-autocomplete #auto

    #krajeSelect>
    <mat-option *ngFor="let option of krajeValues" [value]="option">
      {{option}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<mat-form-field class="fill-width" appearance="outline">
  <mat-label>Popis místa</mat-label>
  <textarea matInput [(ngModel)]="currentData.placeDescription" #placeDescriptionInput></textarea>
</mat-form-field>
<mat-form-field class="fill-width" appearance="outline">
  <mat-label>Popis rizik</mat-label>
  <textarea matInput [(ngModel)]="currentData.riskDescription" #riskDescriptionInput></textarea>
</mat-form-field>
<mat-form-field class="fill-width" appearance="outline">
  <mat-label>GPS Souřadnice</mat-label>
  <input matInput [(ngModel)]="currentData.latlng" type="text"/>
  <mat-hint>např: 49.838440, 18.265094</mat-hint>
</mat-form-field>
<mat-form-field class="fill-width" appearance="outline">
  <mat-label>Závažnost</mat-label>
  <input matInput [(ngModel)]="currentData.zavaznost" type="text" #zavaznostInput/>
</mat-form-field>
<mat-form-field class="fill-width" appearance="outline">
  <mat-label>Město</mat-label>
  <input matInput [(ngModel)]="currentData.mesto" type="text" #zavaznostInput/>
</mat-form-field>
<mat-form-field class="fill-width" appearance="outline">
  <mat-label>Stav místa</mat-label>
  <mat-select [(value)]="currentData.status" #statusSelect>
    <mat-option
      *ngFor="let value of statusValues"
      [value]="value">
      {{statuses[value]}}
    </mat-option>
  </mat-select>
</mat-form-field>
<h2>Nehody</h2>
<div class="accidents">
  <mat-form-field appearance="outline">
    <mat-label>Hmotné škody</mat-label>
    <input matInput [(ngModel)]="currentData.accidents.hmotneSkody" type="number" label="HŠ"/>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Lehká zranění</mat-label>
    <input matInput [(ngModel)]="currentData.accidents.lehkaZraneni" type="number" label="HŠ"/>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Těžká zranění</mat-label>
    <input matInput [(ngModel)]="currentData.accidents.tezkaZraneni" type="number" label="HŠ"/>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Úmrtí</mat-label>
    <input matInput [(ngModel)]="currentData.accidents.umrti" type="number" label="HŠ"/>
  </mat-form-field>
</div>
<h2>Vývoj místa</h2>
<button
  mat-raised-button
  color="primary"
  class="fill-width"
  (click)="addProgress()">
  <mat-icon>add</mat-icon>
  Nový vývoj
</button>
<mat-accordion>
  <mat-expansion-panel
    *ngFor="let progress of currentData.progress;let i = index">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{progress.title == null ? 'Nový vývoj' : progress.title}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-action-row>
      <button
        mat-flat-button
        (click)="removeProgress(i)">
        <mat-icon>delete</mat-icon>
        SMAZAT
      </button>
    </mat-action-row>
    <mat-form-field class="fill-width">
      <mat-label>Název</mat-label>
      <input matInput [(ngModel)]="progress.title" type="text" #riskDescriptionInput/>
    </mat-form-field>
    <mat-form-field class="fill-width">
      <mat-label>Popis</mat-label>
      <textarea matInput [(ngModel)]="progress.description" #riskDescriptionInput></textarea>
    </mat-form-field>
  </mat-expansion-panel>
</mat-accordion>

<h2>Galerie</h2>
<button
  mat-raised-button
  color="primary"
  class="fill-width"
  (click)="addGallery()">
  <mat-icon>add</mat-icon>
  Nová galerie
</button>
<mat-accordion>
  <mat-expansion-panel
    *ngFor="let gallery of currentData.galleries;let galleryIndex = index">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{gallery.name == null ? 'Nová Galerie' : gallery.name}}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-action-row>
      <button
        mat-flat-button
        (click)="removeGallery(galleryIndex)">
        <mat-icon>delete</mat-icon>
        SMAZAT
      </button>
    </mat-action-row>
    <div class="gallery-section">
      <mat-form-field class="fill-width">
        <mat-label>Název galerie</mat-label>
        <input matInput [(ngModel)]="gallery.name" type="text"/>
      </mat-form-field>
      <div class="images-wrapper">
        <mat-card *ngFor="let photo of gallery.photos;let photoIndex = index" [ngClass]="{uploaded: photo.photoToUpload == null}">
          <mat-card-header>
            <mat-card-subtitle>{{photo.photo}}</mat-card-subtitle>
          </mat-card-header>
          <app-photo [photo]="photo" [point]="currentData" [gallery]="gallery"></app-photo>
          <!--<img mat-card-image [src]="photo.getUrlFor(currentData, gallery, true) | async" alt="Photo">-->
          <mat-card-actions>
            <button
              mat-flat-button
              class="remove-button"
              (click)="removePhoto(galleryIndex, photoIndex)">
              Smazat fotku
              <mat-icon>delete</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card>
        <div class="box">
          <input type="file" name="file-6[]" [attr.id]="galleryIndex" class="inputfile inputfile-5" accept="image/*"
                 data-multiple-caption="{count} files selected"
                 multiple
                 (change)="handleFileInput($event, galleryIndex)"/>
          <label [attr.for]="galleryIndex">
            <mat-icon>add</mat-icon>
            <span></span></label>
        </div>
      </div>
    </div>

  </mat-expansion-panel>
</mat-accordion>

<div mat-dialog-actions>
  <button
    mat-raised-button
    class="send-button"
    (click)="send()">Odeslat
  </button>
</div>
</div>

