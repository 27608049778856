import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {User} from '../../models/User';
import {GoogleMap} from '@angular/google-maps';
import MarkerClusterer from '@google/markerclustererplus';
import {getMapBoundsDebounced} from '../../utils/utils';
import {IProblem, Problem} from '../../models/Problem';
import {BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';
import {MatDrawer} from '@angular/material/sidenav';
import {AngularFirestore, DocumentChangeAction} from '@angular/fire/firestore';
import {map} from 'rxjs/operators';
import {IMapDataType, IMapPoint} from '../../models/IMapProblem';
import {MatDialog} from '@angular/material/dialog';
import {LoginFormDialogComponent} from '../../forms/login-form/login-form/login-form.component';
import {ProblemDialogDetailComponent} from '../../forms/problem-detail-dialog/problem-dialog-detail.component';
import {EditProblemDialogComponent} from '../../forms/edit-problem-dialog/edit-problem-dialog.component';
import {AngularFireAuth} from '@angular/fire/auth';
import {MapFilterKeys} from '../../utils/constants';
import {ITir, Tir} from '../../models/Tir';
import {TirDialogDetailComponent} from '../../forms/tir-detail-dialog/tir-dialog-detail.component';
import {EditTirDialogComponent} from '../../forms/edit-tir-dialog/edit-tir-dialog.component';


type IFilter = {
  [Key in MapFilterKeys]: boolean;
};


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
  public zoom = 7;
  public center: google.maps.LatLngLiteral = {
    lat: 49.7,
    lng: 15
  };

  public options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: true,
    disableDoubleClickZoom: false,
    scrollwheel: true,
    restriction: {
      latLngBounds: new google.maps.LatLngBounds(
        new google.maps.LatLng(48, 11),
        new google.maps.LatLng(51.2, 19.5)
      )
    },
    maxZoom: 20,
    minZoom: 7,
    fullscreenControl: true
  };

  public loaderOptions = {
    backdropBorderRadius: '3px',
    backdropBackgroundColour: 'rgba(255,255,255,0.3)'
  };
  @ViewChild('mapEl')
  private googleMap: GoogleMap;

  @ViewChild('matDrawer')
  public drawer: MatDrawer;

  private clusterer: MarkerClusterer;
  filterEnum = MapFilterKeys;
  currentFilters: IFilter = {
    [MapFilterKeys.CAR_COMPLETE]: true,
    [MapFilterKeys.CAR_IN_PROGRESS]: true,
    [MapFilterKeys.CAR_TODO]: true,
    [MapFilterKeys.CAR_NOT_CHECKED]: false,
    [MapFilterKeys.TIR_TODO]: false,
    [MapFilterKeys.TIR_NOT_CHECKED]: false
  };

  bounds$: BehaviorSubject<google.maps.LatLngBounds> = new BehaviorSubject(new google.maps.LatLngBounds());
  filters$: BehaviorSubject<IFilter> = new BehaviorSubject(this.currentFilters);
  visiblePoints: IMapPoint[] = [];
  loggedInUser: User;

  public loading = false;
  destroy$: Subject<{}> = new Subject();


  constructor(private afs: AngularFirestore,
              private afAuth: AngularFireAuth,
              public dialog: MatDialog) {
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.loggedInUser = user;
      } else {
        this.loggedInUser = null;
      }

    });
  }

  ngOnDestroy(): void {
    this.destroy$.next({});
    this.destroy$.unsubscribe();
  }

  showPosition(latlng: google.maps.LatLng): void {
    this.googleMap.zoom = 12;
    this.googleMap.panTo(latlng);
    // setTimeout(() => { }, 1000); // Zoom in after 1 sec
  }

  ngOnInit(): void {
    // fetch problems collection
    const problems$: Observable<IMapPoint[]> = this.afs.collection<IProblem>('problems').snapshotChanges().pipe(
      map(actions => actions.map(a => mapActionToType<IProblem>(a))),
      map(problems => problems.map(p => {
          const problem = new Problem(p);
          const marker = createMapMarker(problem);
          const point: IMapPoint = {
            marker,
            data: problem,
            type: 'Problem'
          };
          marker.addListener('click', () => {
            this.showDetail(point);
          });
          return point;
        })
      ),
      /*tap(mapProblems => {
        // side effect to create clusters
        const justMarkers = mapProblems.map(mapProblem => mapProblem.marker);
        this.createClusters(justMarkers);
      })*/
    );

    // fetch tirs collection
    const tirs$ = this.afs.collection<ITir>('tirs').snapshotChanges().pipe(
      map(actions => actions.map(a => mapActionToType<ITir>(a))),
      map(tirs => tirs.map(t => {
        const tir = new Tir(t);
        const marker = createMapMarker(tir);
        const point: IMapPoint = {
          marker,
          data: tir,
          type: 'Tir'
        };
        marker.addListener('click', () => {
          this.showDetail(point);
        });
        return point;
      }))
    );

    // combine collection results
    const data$: Observable<IMapPoint[]> = combineLatest([
      problems$,
      tirs$
    ]).pipe(
      map(([a, b]) => [...a, ...b])
    );

    // subscribe to the streams and transfer data
    this.loading = true;
    combineLatest([this.bounds$, data$, this.filters$])
      .subscribe(([bounds, points, filters]) => {
        const tempFilters = {...filters};
        if (Object.values(filters).every(i => i === false)){
          tempFilters.CAR_IN_PROGRESS = true;
          tempFilters.CAR_TODO = true;
          tempFilters.TIR_TODO = true;
          tempFilters.CAR_COMPLETE = true;
        }
        this.fillVisible(bounds, points, tempFilters);
        const justMarkers = points.map(mapProblem => mapProblem.marker);
        this.createClusters(justMarkers);
        this.loading = false;
      });

  }

  ngAfterViewInit(): void {
    this.clusterer = new MarkerClusterer(this.googleMap.data.getMap(), [], {
      styles: [{textLineHeight: 48, height: 40, width: 40, url: 'assets/sign_neutral.svg'}],
      zoomOnClick: true
    });
  }


  determineMarkerVisibility(point: IMapPoint, filters: IFilter): void {
    point.marker.setVisible(false);
    for (const key of Object.keys(filters)) {
      if (filters[key] && point.data.filterKey === key) {
        point.marker.setVisible(true);
      }
    }
  }

  showLogin(): void {
    this.dialog.open(LoginFormDialogComponent);
  }

  showCreateDialog(type: IMapDataType): void {
    this.dialog.open(editDialogComponentTypes[type], {
      data: null,
      autoFocus: false,
    });
  }

  showDetail(point: IMapPoint): void {
    this.dialog.open(detailDialogComponentTypes[point.type], {
      minWidth: '75%',
      data: point,
      autoFocus: false,
    });
  }

  fillVisible(bounds: google.maps.LatLngBounds, points: IMapPoint[], filters: IFilter): void {
    this.visiblePoints = [];
    points.forEach(point => {
      this.determineMarkerVisibility(point, filters);
      if (bounds.contains(point.marker.getPosition())) {
        if (point.marker.getVisible()) {
          this.visiblePoints.push(point);
        }
      }
    });
  }

  boundsChanged(): void {
    getMapBoundsDebounced(this.googleMap).then(async (bounds: google.maps.LatLngBounds) => {
      this.bounds$.next(bounds);
    });
  }

  private createClusters(markers: google.maps.Marker[]): void {
    if (this.clusterer) {
      this.clusterer.setIgnoreHidden(true);
      this.clusterer.clearMarkers();
      this.clusterer.addMarkers(markers);
    }
  }

  public toggleDrawer(): void {
    this.drawer.toggle();
  }


  /* No time to think, cant think of easy way how to make it less redundant */
  public changeFilter(newState: boolean, color: MapFilterKeys): void {
    this.currentFilters[color] = newState;
    this.filters$.next(this.currentFilters);
  }
}


const mapActionToType = <T>(a: DocumentChangeAction<any>) => ({
  id: a.payload.doc.id,
  ...a.payload.doc.data()
} as T);

const createMapMarker = (point: Problem | Tir): google.maps.Marker => {
  if (!point.location?.geopoint?.latitude) {
    console.warn("PT without lat",point);
  }
  return new google.maps.Marker({
    icon: {
      url: point.iconUrl,
      scaledSize: new google.maps.Size(40, 40),
    },
    position: point.getGMapLatLng(),
    clickable: true,
    visible: true,
  });
};

export const detailDialogComponentTypes: Record<IMapDataType, any> = {
  Problem: ProblemDialogDetailComponent,
  Tir: TirDialogDetailComponent,
};

export const editDialogComponentTypes: Record<IMapDataType, any> = {
  Problem: EditProblemDialogComponent,
  Tir: EditTirDialogComponent,
};

