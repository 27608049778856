import {MapFilterKeys, ProblemStatus} from '../utils/constants';
import {BasePointData, IMapPointData} from './BasePointData';


export interface ITir extends IMapPointData {
  description?: string;
  category: string;
}


export class Tir extends BasePointData implements ITir {
  description: string;
  category: string;
  // status = -1;


  constructor(attrs?: any) {
    super(attrs);
  }

  get iconUrl(): string {
    return `assets/tir_${this.colorClass}.svg`;
  }


  get displayTitle(): string {
    return this.title ? this.title : (this.mesto ? `Problém z obce ${this.mesto}` : 'Bez názvu');
  }

  get filterKey(): MapFilterKeys {
    if (this.status >= ProblemStatus['Neřeší se']) {
      return MapFilterKeys.TIR_TODO;
    } else if (this.status === ProblemStatus['Návrh']) {
      return MapFilterKeys.TIR_NOT_CHECKED;
    }
  }

  get colorClass(): string {
    switch (this.filterKey) {
      case MapFilterKeys.TIR_TODO:
        return 'red';
      case MapFilterKeys.TIR_NOT_CHECKED:
        return 'black';
      default:
        return 'orange';
    }
  }
}
