import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {AngularFireAuth} from '@angular/fire/auth';
import {FormControl, FormGroup} from '@angular/forms';
import {User} from '../../../models/User';
import {AngularFirestore} from '@angular/fire/firestore';
import {login, logout} from '../../../utils/authFns';



@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormDialogComponent {

  loggedInUser?: User;

  loginForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });

  constructor(
    public dialogRef: MatDialogRef<LoginFormDialogComponent>,
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth) {

    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.loggedInUser = user;
        localStorage.setItem('user', JSON.stringify(this.loggedInUser));
      } else {
        localStorage.setItem('user', null);
      }
    });
  }

  public async logout(): Promise<void> {
    await logout(this.afAuth).then(_ => {
      this.loggedInUser = null;
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public async submit(): Promise<void> {
    const loginResult = await login(this.afAuth, this.loginForm.value.email, this.loginForm.value.password);
  }



}
