<section class="stepper">
  <h1>Stav místa</h1>
  <div class="steps">
    <div *ngFor="let currentStatusValue of statusesValues; let isLast = last"
         [ngClass]="{'step': true,
         'step-complete': (isLast && (status === currentStatusValue)) || status > currentStatusValue,
         'step-active': !isLast && (status === currentStatusValue)}">
      <div>
        <div class="circle">
          <mat-icon *ngIf="(isLast && (status === currentStatusValue)) || status > currentStatusValue;else number">check</mat-icon>
          <span *ngIf="status <= currentStatusValue">{{currentStatusValue + 1}}</span>
        </div>
      </div>
      <div>
        <div class="title">{{statuses[currentStatusValue]}}</div>
      </div>
    </div>
  </div>

</section>
<ng-template #number>

</ng-template>
