import {Component, Inject, Input, OnInit} from '@angular/core';
import {IProblem, Problem} from '../../models/Problem';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AngularFireAuth} from '@angular/fire/auth';
import {User} from '../../models/User';
import {Photo} from '../../models/Photo';
import {EditProblemDialogComponent} from '../edit-problem-dialog/edit-problem-dialog.component';
import {AngularFirestore} from '@angular/fire/firestore';
import {ITir} from '../../models/Tir';
import {IMapPoint} from "../../models/IMapProblem";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-problem-detail-dialog',
  templateUrl: './problem-dialog-detail.component.html',
  styleUrls: ['./problem-dialog-detail.component.scss']
})
export class ProblemDialogDetailComponent implements OnInit {
  public problem: IProblem;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ProblemDialogDetailComponent>,
    private afs: AngularFirestore,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA) point: IMapPoint<IProblem>) {
    const { data } = point;
    this.problem = data;
  }

  ngOnInit(): void {
  }

  public close(): void {
    this.dialogRef.close();
  }

  public edit(): void {
    this.dialogRef.close();
    this.dialog.open(EditProblemDialogComponent, {
      data: this.problem,
      autoFocus: false,
    });
  }

  async delete(): Promise<void> {
    await this.afs.collection('problems').doc(this.problem.id).delete();
    this.dialogRef.close();
  }

}
