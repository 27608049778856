import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {IMapPoint} from '../../models/IMapProblem';
import {Problem} from '../../models/Problem';
import {Tir} from "../../models/Tir";

@Component({
  selector: 'app-map-list-item',
  templateUrl: './map-list-item.component.html',
  styleUrls: ['./map-list-item.component.scss']
})
export class MapListItemComponent implements OnInit {
  @Input()
  mapPoint: IMapPoint;

  @Output() positionClicked = new EventEmitter<google.maps.LatLng>();
  @Output() detailClicked = new EventEmitter<Problem | Tir>();

  imgSrc: string;

  constructor() { }

  async ngOnInit(): Promise<void> {
    this.imgSrc = await this.mapPoint.data.getFeaturePhotoUrl();
  }

}
