// noinspection JSNonASCIINames
import {Gallery} from './Gallery';
import {Accidents} from './Accidents';
import {Progress} from './Progress';
import * as firebase from 'firebase';
import {MapFilterKeys, ProblemStatus} from '../utils/constants';
import {BasePointData, IMapPointData} from './BasePointData';


export interface IProblem extends IMapPointData {
  accidents: Accidents;
  cost: { cost: number, year: number };

  placeDescription?: string;
  progress: Progress[];
  riskDescription: string;
  // status: ProblemStatus;
  zavaznost?: string;

  // not yet implemented
  featurePhotoUrl?: string;

}

export class Problem extends BasePointData implements IProblem {
  accidents: Accidents;
  cost: { cost: number; year: number };
  featurePhotoUrl?: string = null;
  galleries: Gallery[];
  id: string;
  kraj: string;
  location: { geohash: string; geopoint: firebase.firestore.GeoPoint };
  mesto: string;
  placeDescription: string;
  progress: Progress[];
  riskDescription: string;
  status: number;
  title: string;
  zavaznost: string;

  get displayTitle(): string {
    return this.title ? this.title : (this.mesto ? `Problém z obce ${this.mesto}` : 'Bez názvu');
  }

  get displayZavaznost(): string {
    return this.zavaznost ? this.zavaznost : 'Bez hodnocení';
  }

  get displayKraj(): string {
    return this.kraj ? this.kraj : 'Neznámý kraj';
  }

  get header(): string {
    return this.title ?? this.mesto ?? 'Neznámý problém';
  }


  get pinDescription(): string {
    return this.riskDescription.length > 0
      ? `${this.riskDescription.substring(0, this.riskDescription.length > 30 ? 30 : this.riskDescription.length)}... Klikněte pro detail.`
      : `Neznámé riziko. Klikněte pro detail.`;
  }


  get filterKey(): MapFilterKeys {
    let code = MapFilterKeys.CAR_IN_PROGRESS;
    if (this.status === ProblemStatus['Neřeší se']) {
      code = MapFilterKeys.CAR_TODO;
    } else if (this.status === ProblemStatus['Místo je zrealizováno']) {
      code = MapFilterKeys.CAR_COMPLETE;
    } else {
      if (this.status === ProblemStatus.Návrh) {
        code = MapFilterKeys.CAR_NOT_CHECKED;
      }
    }
    return code;
  }

  get colorClass(): string {
    switch (this.filterKey) {
      case MapFilterKeys.CAR_TODO:
        return 'red';
      case MapFilterKeys.CAR_COMPLETE:
        return 'green';
      case MapFilterKeys.CAR_NOT_CHECKED:
        return 'black';
      default:
        return 'orange';
    }
  }

  get iconUrl(): string {
    return `assets/car_${this.colorClass}.svg`;
  }

  constructor(attrs?: any) {
    super(attrs);
    if (!this.accidents) {
      this.accidents = new Accidents();
    }
    if (!this.progress) {
      this.progress = [];
    }
  }
}
