import {kraje} from '../utils/constants';
import {Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AngularFireStorage} from '@angular/fire/storage';
import {AngularFirestore} from '@angular/fire/firestore';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Photo} from '../models/Photo';
import {Gallery} from '../models/Gallery';
import {v1 as uuidv1, v5 as uuidv5} from 'uuid';
import {BasePointData} from '../models/BasePointData';

export class BaseEditDialog<T extends BasePointData> {
  public loading = false;
  public krajeValues = kraje;
  public currentData: T;
  public collectionName: string;

  constructor(@Inject(MAT_DIALOG_DATA) data: any,
              protected afStorage: AngularFireStorage,
              private afs: AngularFirestore,
              private snackBar: MatSnackBar,
              private dialogRef: MatDialogRef<any>
  ) {
    this.currentData = data;
  }

  handleFileInput(event: Event, galleryIndex: number): void {
    const files: FileList = (event.target as HTMLInputElement).files;
    for (let i = 0; i < files.length; i++) {
      const photo = new Photo();
      photo.photoToUpload = files.item(i);
      const uuid = uuidv1();
      photo.path = `${uuid}.jpg`;
      photo.photo = `opt_${photo.path}`;
      photo.thumbnail = `thumbnail_${photo.path}`;
      this.currentData.galleries[galleryIndex].photos.push(photo);
    }
  }

  removePhoto(galleryIndex: number, photoIndex: number): void {
    this.currentData.galleries[galleryIndex].photos.splice(photoIndex, 1);
  }

  removeGallery(galleryIndex: number): void {
    const gallery = this.currentData.galleries[galleryIndex];
    for (let i = 0; i < gallery.photos.length; i++) {
      this.removePhoto(galleryIndex, i);
    }
    this.currentData.galleries.splice(galleryIndex);
  }


  addGallery(): void {
    // NameSpace_X500
    const path = uuidv5(`import_${this.currentData.id}/${uuidv1()}`, '6ba7b814-9dad-11d1-80b4-00c04fd430c8');

    const newGallery = new Gallery();
    newGallery.path = path;
    this.currentData.galleries.push(newGallery);
  }

  isBlob(input) {
    return 'Blob' in window && input instanceof Blob;
  }

  async uploadAllPhotos(): Promise<void> {
    for (const gallery of this.currentData.galleries) {
      for (const photo of gallery.photos) {
        if (photo.photoToUpload && this.isBlob(photo.photoToUpload)) {
          const desiredPhotoPath = this.afStorage.ref(`${this.currentData.id}/${gallery.path}/${photo.path}`);
          await desiredPhotoPath.put(photo.photoToUpload);
          delete photo.photoToUpload;
        }
      }
    }
  }

  async onSendComplete(): Promise<void> {
    this.loading = false;
    this.dialogRef.close();
    this.snackBar.open('Úspěšně odesláno.', '', {
      duration: 2000,
    });
  }


  async send(): Promise<void> {
    if (!this.currentData.latlng) {
      this.snackBar.open('Nelze odeslat - chybí pozice', '', {
        duration: 2000,
      });
      return;
    }

    if (!this.currentData.title) {
      this.snackBar.open('Nelze odeslat - chybí název', '', {
        duration: 2000,
      });
      return;
    }

    if (!this.currentData.kraj) {
      this.snackBar.open('Nelze odeslat - chybí kraj', '', {
        duration: 2000,
      });
      return;
    }

    if (this.currentData.status == null) {
      this.snackBar.open('Nelze odeslat - chybí stav', '', {
        duration: 2000,
      });
      return;
    }


    // start upload
    this.loading = true;
    if (this.currentData.id) {
      await this.uploadAllPhotos();
      this.afs.collection(this.collectionName)
        .doc(this.currentData.id)
        .update(this.currentData.toJson())
        .then(async (_) => {
          return this.onSendComplete();
        });
    } else {
      if (!this.currentData.location.geopoint && this.currentData.tempLatLng) {
        this.currentData.latlng = this.currentData.tempLatLng;
      }
      if (this.currentData.tempLatLng) {
        this.snackBar.open('Nelze odeslat - neplatná GPS', '', {
          duration: 2000,
        });
        throw new Error('Neplatná GPS pozice');
      }
      this.afs.collection(this.collectionName)
        .add(this.currentData.toJson())
        .then(async (doc) => {
          this.currentData.id = doc.id;
          await this.uploadAllPhotos();
          /*await doc.update(this.currentData.toJson());
          const updatedDoc = await doc.get();
          this.currentData.galleries = updatedDoc.data()?.galleries;*/
          return this.onSendComplete();
        });
    }
  }
}
