import {Costs} from './Costs';

export class Accidents {
  public hmotneSkody = 0;
  public lehkaZraneni = 0;
  public tezkaZraneni = 0;
  public umrti = 0;

  getPriceBasedOnCosts(costs: Costs): number {
    let total = 0;
    total += this.hmotneSkody * costs.hmotneSkody;
    total += this.lehkaZraneni * costs.lehkaZraneni;
    total += this.tezkaZraneni * costs.tezkaZraneni;
    total += this.umrti * costs.umrti;
    return total;
  }
}
