import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AngularFirestore} from '@angular/fire/firestore';
import {ITir} from '../../models/Tir';
import {IMapPoint} from '../../models/IMapProblem';
import {AuthService} from '../../services/auth.service';
import {EditTirDialogComponent} from '../edit-tir-dialog/edit-tir-dialog.component';


@Component({
  selector: 'app-tir-detail-dialog',
  templateUrl: './tir-dialog-detail.component.html',
  styleUrls: ['./tir-dialog-detail.component.scss']
})
export class TirDialogDetailComponent implements OnInit {

  public tir: ITir;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<TirDialogDetailComponent>,
    private afs: AngularFirestore,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA) point: IMapPoint<ITir>) {
    const {data} = point;
    this.tir = data;
  }

  ngOnInit(): void {
  }

  public close(): void {
    this.dialogRef.close();
  }

  public edit(): void {
    this.dialogRef.close();
    this.dialog.open(EditTirDialogComponent, {
      data: this.tir,
      autoFocus: false,
    });
  }

  async delete(): Promise<void> {
    await this.afs.collection('tirs').doc(this.tir.id).delete();
    this.dialogRef.close();
  }

}
