import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {LoginFormDialogComponent} from './forms/login-form/login-form/login-form.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MapComponent} from './components/map/map.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {FilterButtonComponent} from './components/filter-button/filter-button.component';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatToolbarModule} from '@angular/material/toolbar';
import {GoogleMapsModule} from '@angular/google-maps';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import {MapListItemComponent} from './components/map-list-item/map-list-item.component';
import {ProblemDialogDetailComponent} from './forms/problem-detail-dialog/problem-dialog-detail.component';
import {MapStatusComponent} from './components/map-status/map-status.component';
import {NgxLoadingModule} from 'ngx-loading';
import {MatDividerModule} from '@angular/material/divider';
import {EditProblemDialogComponent} from './forms/edit-problem-dialog/edit-problem-dialog.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSelectModule} from '@angular/material/select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCardModule} from '@angular/material/card';
import {PhotoComponent} from './components/photo/photo.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CrystalLightboxModule} from '@crystalui/angular-lightbox';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {StatComponent} from './components/stat/stat.component';
import {TirDialogDetailComponent} from './forms/tir-detail-dialog/tir-dialog-detail.component';
import { EditTirDialogComponent } from './forms/edit-tir-dialog/edit-tir-dialog.component';
import { PhotoGalleryComponent } from './components/photo-gallery/photo-gallery.component';
import {MatMenuModule} from "@angular/material/menu";

@NgModule({
  declarations: [
    AppComponent,
    LoginFormDialogComponent,
    MapComponent,
    FilterButtonComponent,
    MapListItemComponent,
    ProblemDialogDetailComponent,
    TirDialogDetailComponent,
    MapStatusComponent,
    EditProblemDialogComponent,
    PhotoComponent,
    StatComponent,
    EditTirDialogComponent,
    PhotoGalleryComponent
  ],
    imports: [
        AngularFireModule.initializeApp(environment.firebase),
        BrowserModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatDialogModule,
        MatSidenavModule,
        MatIconModule,
        MatInputModule,
        ReactiveFormsModule,
        MatToolbarModule,
        GoogleMapsModule,
        NgxLoadingModule,
        MatDividerModule,
        MatExpansionModule,
        FormsModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatCardModule,
        MatTooltipModule,
        CrystalLightboxModule,
        MatSnackBarModule,
        MatMenuModule,
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
