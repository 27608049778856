export const defaultVerticalPadding = 8.0;

export const defaultHorizontalPadding = 40.0;

export const cardPadding = 16.0;
export const shortDividerWidth = 24.0;

export const iconSize = 72.0;
export const iconPadding = 16.0;

export const fontSizeLarge = 16.0;
export const fontSizeRegular = 12.0;
export const fontSizeSmall = 8.0;

export const kraje = [
  'Hlavní město Praha',
  'Středočeský kraj',
  'Jihočeský kraj',
  'Plzeňský kraj',
  'Karlovarský kraj',
  'Ústecký kraj',
  'Liberecký kraj',
  'Královéhradecký kraj',
  'Pardubický kraj',
  'Olomoucký kraj',
  'Moravskoslezský kraj',
  'Jihomoravský kraj',
  'Zlínský kraj',
  'Kraj Vysočina'
];

export enum ProblemStatus {
  'Návrh' = -1,
  'Neřeší se' = 0,
  'Probíhá analýza'= 1,
  'Probíhá zpracování dokumentace' = 2,
  'Probíhá územní řízení' = 3,
  'Probíhá stavební řízení' = 4,
  'Probíhá výstavba' = 5,
  'Místo je zrealizováno' = 6,
}

export enum MapFilterKeys {
  'CAR_COMPLETE'= 'CAR_COMPLETE',
  'CAR_IN_PROGRESS' = 'CAR_IN_PROGRESS',
  'CAR_TODO' = 'CAR_TODO',
  'CAR_NOT_CHECKED' = 'CAR_NOT_CHECKED',
  'TIR_TODO' = 'TIR_TODO',
  'TIR_NOT_CHECKED' = 'TIR_NOT_CHECKED'
}

export const getStatusesObject = () => {
  const returnObject = {};
  getStatusesValues().forEach(enumValue => {
    returnObject[enumValue] = ProblemStatus[enumValue];
  });
  return returnObject;
};

export const getStatusesValues = (): number[] => {
  const enumMembers: any[] = Object.keys(ProblemStatus).map(key => ProblemStatus[key]);

  // we are only interested in the numeric identifiers as these represent the values
  return enumMembers.filter(v => typeof v === 'number');
};

