import {Component, Input, OnInit} from '@angular/core';

import {IMapPointData} from '../../models/BasePointData';

@Component({
  selector: 'app-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.scss']
})
export class PhotoGalleryComponent implements OnInit {
  @Input()
  public point!: IMapPointData;

  constructor() {
  }

  ngOnInit(): void {
  }

}
