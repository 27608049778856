import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Tir} from '../../models/Tir';
import {BaseEditDialog} from '../base-edit-dialog';
import {getStatusesValues, ProblemStatus} from '../../utils/constants';


@Component({
  selector: 'app-edit-tir-dialog',
  templateUrl: './edit-tir-dialog.component.html',
  styleUrls: ['./edit-tir-dialog.component.scss']
})
export class EditTirDialogComponent extends BaseEditDialog<Tir> implements OnInit {
  public categories = [
    'Problémové parkování',
    'Nabídka místa pro odpočívku',
    'Zvážení možnosti odpočívky',
    'Ostatní'
  ];

  statusValues = getStatusesValues();
  statuses = ProblemStatus;

  constructor(@Inject(MAT_DIALOG_DATA) data: Tir,
              afStorage: AngularFireStorage,
              afs: AngularFirestore,
              snackBar: MatSnackBar,
              dialogRef: MatDialogRef<EditTirDialogComponent>) {
    super(data, afStorage, afs, snackBar, dialogRef);
    if (!data) {
      this.currentData = new Tir();
    }
    this.collectionName = 'tirs';
  }

  ngOnInit(): void {
  }

}


