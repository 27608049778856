import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {User} from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public currentUser$: Observable<User>;

  constructor(public afAuth: AngularFireAuth) {
    this.currentUser$ = this.afAuth.authState.pipe(
      tap(user => {
        if (user) {
          localStorage.setItem('user', JSON.stringify(user));
        } else {
          localStorage.setItem('user', null);
        }
      })
    );
  }
}
