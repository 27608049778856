import {GoogleMap} from "@angular/google-maps";
import * as firebase from 'firebase/app';
import 'firebase/firestore';

export function getBaseName(fullPath: string): string {
  return fullPath.substring(fullPath.lastIndexOf('/') + 1);
}
export const debounce = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
  let timeout;

  return (...args: Parameters<F>): Promise<ReturnType<F>> =>
    new Promise(resolve => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => resolve(func(...args)), waitFor);
    })
}

export const getMapBoundsDebounced = debounce((gMap: GoogleMap) => gMap.getBounds(), 500);

export const convertStringToGeoPoint = (text: string, validate = true) => {
  const splitted = text.split(/[ ,]+/);
  if(splitted.length === 2){
    if(
      (isNumber(splitted[0]) && Math.abs(Number(splitted[0])) <= 90) &&
      (isNumber(splitted[1]) && Math.abs(Number(splitted[1])) <= 180)
    ){
      return new firebase.firestore.GeoPoint(Number(splitted[0]),Number(splitted[1]));
    }
  }
  return null;
}

export function isNumber(value: string | number): boolean
{
  return ((value != null) &&
    (value !== '') &&
    !isNaN(Number(value.toString())));
}




