<h2 mat-dialog-title class="text-primary">
  {{tir.displayTitle}}
</h2>
<mat-dialog-content>
  <!--<pk-photo-detail #photoDetail [imageUrl]="imageUrl"></pk-photo-detail>-->
  <!--<app-map-status [status]="tir.status"></app-map-status>-->

  <h2>Kategorie</h2>
  <mat-divider></mat-divider>
  <p>{{tir.category}}</p>

  <h2>Popis</h2>
  <mat-divider></mat-divider>
  <p>{{tir.description}}</p>

  <ng-container *ngIf="authService.currentUser$ | async as currentUser">
    <h2>Kontakt</h2>
    <mat-divider></mat-divider>
    <p>{{tir.userEmail ?  tir.userEmail : 'Vytvořeno administrátorem'}}</p>
  </ng-container>

  <h2>Galerie</h2>
  <mat-divider></mat-divider>
  <app-photo-gallery [point]="tir"></app-photo-gallery>

  <h2>Kraj</h2>
  <mat-divider></mat-divider>
  <p>{{tir.kraj}}</p>

  <div *ngIf="tir.mesto">
    <h2>Město</h2>
    <mat-divider></mat-divider>
    <p>{{tir.mesto}}</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button color="secondary" (click)="close()">
    Zavřít
  </button>
  <ng-container *ngIf="authService.currentUser$ | async as currentUser">
    <button mat-flat-button color="secondary" (click)="edit()">
      Upravit
    </button>
    <button mat-stroked-button color="accent" (click)="delete()">
      Smazat
    </button>
  </ng-container>
</mat-dialog-actions>


<ng-template #noPhotos>
  <span class="no-photos">Galerie neobsahuje žádné fotografie</span>
</ng-template>
