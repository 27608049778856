<mat-drawer-container
  [hasBackdrop]="false"
  [class.drawer-opened]="matDrawer.opened">
  <mat-drawer #matDrawer mode="side" opened="true" position="end">
    <ng-container *ngIf="visiblePoints.length > 0; else noPointsFound">
      <div *ngFor="let point of visiblePoints;let i = index,let last = last">
        <app-map-list-item #item [mapPoint]="point" (detailClicked)="showDetail(point)" (positionClicked)="showPosition($event)"></app-map-list-item>
        <mat-divider *ngIf="!last"></mat-divider>
      </div>
    </ng-container>
    <ng-template #noPointsFound>
      <div class="empty">
        <mat-icon>receipt_long</mat-icon>
        <h2>Nebyly nalezeny žádné body.</h2>
      </div>
    </ng-template>
  </mat-drawer>
  <mat-drawer-content class="material-content">
    <mat-toolbar color="primary">
        <!-- This button will toggle (open/close) the drawer -->
        <button mat-icon-button
                class="white material-drawer-button" (click)="toggleDrawer()">
          <mat-icon>menu</mat-icon>
        </button>
        <span class="toolbar-spacer"></span>
        <button class="white" mat-icon-button (click)="showLogin()">
          <mat-icon>person</mat-icon>
        </button>

        <button class="white" mat-icon-button *ngIf="loggedInUser" [matMenuTriggerFor]="menu">
          <mat-icon>add_location</mat-icon>
        </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="showCreateDialog('Problem')">Přidat problém</button>
        <button mat-menu-item (click)="showCreateDialog('Tir')">Přidat tirák</button>
      </mat-menu>
    </mat-toolbar>
    <div class="pk-content">
      <div class="mdc-card bottom-filter">
        <app-filter-button imgPath="/car_red.svg"
                           text="Neřeší se"
                           (stateChange)="changeFilter($event,filterEnum.CAR_TODO)"
                           [initialState]="currentFilters.CAR_TODO">
        </app-filter-button>
        <app-filter-button imgPath="/car_orange.svg"
                           text="Řeší se"
                           (stateChange)="changeFilter($event,filterEnum.CAR_IN_PROGRESS)"
                           [initialState]="currentFilters.CAR_IN_PROGRESS">
        </app-filter-button>
        <app-filter-button imgPath="/car_green.svg"
                           text="Vyřešeno"
                           (stateChange)="changeFilter($event,filterEnum.CAR_COMPLETE)"
                           [initialState]="currentFilters.CAR_COMPLETE"
        ></app-filter-button>
        <app-filter-button *ngIf="loggedInUser"
                           imgPath="/car_black.svg"
                           text="Ke kontrole"
                           (stateChange)="changeFilter($event,filterEnum.CAR_NOT_CHECKED)"
                           [initialState]="currentFilters.CAR_NOT_CHECKED"
        ></app-filter-button>
        <app-filter-button imgPath="/tir_red.svg" text="TIR parkování"
                           (stateChange)="changeFilter($event,filterEnum.TIR_TODO)"
                           [initialState]="currentFilters.TIR_TODO">
        </app-filter-button>
        <app-filter-button *ngIf="loggedInUser"
                           imgPath="/tir_black.svg" text="TIR kontrola"
                           (stateChange)="changeFilter($event,filterEnum.TIR_NOT_CHECKED)"
                           [initialState]="currentFilters.TIR_NOT_CHECKED">
        </app-filter-button>

      </div>
      <google-map
        #mapEl
        [width]="'100%'"
        [height]="'100%'"
        [zoom]="zoom"
        (boundsChanged)="boundsChanged()"

        [center]="center"
        [options]="options">
      </google-map>
      <ngx-loading [show]="loading" [config]=""></ngx-loading>
    </div>
  </mat-drawer-content>

</mat-drawer-container>
