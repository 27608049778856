import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Problem} from '../../models/Problem';
import {getStatusesValues, kraje, ProblemStatus} from '../../utils/constants';
import {AngularFirestore} from '@angular/fire/firestore';
import {Progress} from '../../models/Progress';
import {AngularFireStorage} from '@angular/fire/storage';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BaseEditDialog} from "../base-edit-dialog";


@Component({
  selector: 'app-edit-problem-dialog',
  templateUrl: './edit-problem-dialog.component.html',
  styleUrls: ['./edit-problem-dialog.component.scss']
})
export class EditProblemDialogComponent extends BaseEditDialog<Problem> implements OnInit {
  statusValues = getStatusesValues();
  statuses = ProblemStatus;

  constructor(@Inject(MAT_DIALOG_DATA) data: Problem,
              afStorage: AngularFireStorage,
              afs: AngularFirestore,
              snackBar: MatSnackBar,
              dialogRef: MatDialogRef<EditProblemDialogComponent>) {
    super(data, afStorage, afs, snackBar, dialogRef);
    if (!data) {
      this.currentData = new Problem();
    }
    this.collectionName = 'problems';
  }

  ngOnInit(): void {

  }

  removeProgress(progressIndex: number): void {
    this.currentData.progress.splice(progressIndex, 1);
  }

  addProgress(): void {
    this.currentData.progress.push(new Progress());
  }

}
