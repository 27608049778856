import {Component, HostListener, Input, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss']
})
export class FilterButtonComponent implements OnInit {

  @Output('stateChange') change = new EventEmitter<boolean>();

  currentState = true;

  @Input()
  text: string;

  @Input()
  initialState?: boolean;

  @Input()
  imgPath: string;

  @HostListener('click')
  onClick(e): void {
    this.currentState = !this.currentState;
    this.change.emit(this.currentState);
  }

  ngOnInit(): void {
    if (this.initialState != null) {
      this.currentState = this.initialState;
    }
  }

}
