import {Gallery} from './Gallery';
import {environment} from '../../environments/environment';
import {IMapPointData} from './BasePointData';


export class Photo {
  // firebase properties
  public photo: string;
  public path: string;
  public thumbnail: string;
  public createdAt: string;

  // solely for angular upload purposes
  public photoToUpload: File;


  toString(): string {
    return `Photo{photo: ${this.photo}, thumbnail: ${this.thumbnail}, createdAt: ${this.createdAt}`;
  }

  toJsonString(): string {
    return JSON.stringify(this);
  }

  async getUrlFor(point: IMapPointData, gallery: Gallery, getThumbnail: boolean): Promise<string> {
    if (this.photoToUpload?.size > 0) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          resolve(fileReader.result as string);
        };
        fileReader.onerror = () => {
          reject();
        };
        fileReader.readAsDataURL(this.photoToUpload);
      });
    } else {
      const fileName = getThumbnail ? this.thumbnail : this.photo;
      return `https://firebasestorage.googleapis.com/v0/b/${environment.firebase.storageBucket}/o/${point.id}%2F${gallery.path}%2F${fileName}?alt=media`;
    }

  }

}
