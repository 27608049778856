<h2 mat-dialog-title>
  Uživatelská obrazovka
</h2>
<ng-container *ngIf="loggedInUser == null;else loggedInBlock">
  <mat-dialog-content>
    <form [formGroup]="loginForm">
      <mat-form-field appearance="outline" class="fill-width">
        <mat-label>Email</mat-label>
        <input type="email" matInput formControlName="email">
        <mat-icon matSuffix>alternate_email</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="outline" class="fill-width">
        <mat-label>Heslo</mat-label>
        <input type="password" matInput formControlName="password">
        <mat-icon matSuffix>security</mat-icon>
      </mat-form-field>

    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-stroked-button (click)="close()">Zrušit</button>
    <button mat-flat-button class="mat-primary" (click)="submit()">Uložit</button>
  </mat-dialog-actions>
</ng-container>
<ng-template #loggedInBlock>
  <mat-dialog-content>
    <div>Přihlášen jako {{loggedInUser.email}}</div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button (click)="logout()">Odhlásit se
    </button>
  </mat-dialog-actions>

</ng-template>


