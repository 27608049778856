import {Photo} from './Photo';

export class Gallery {
  name: string;
  path: string;
  photos: Photo[];

  constructor() {
    this.photos = [];
  }
}
