<ng-container *ngIf="point.galleries.length > 0;else noPhotos">
  <div class="gallery-wrap" *ngFor="let gallery of point.galleries;let last = last">
    <h3>{{gallery.name}}</h3>
    <div class="photos" *ngIf="gallery.photos.length > 0; else noPhotos">
      <div class="photo" *ngFor="let photo of gallery.photos">
        <app-photo [gallery]="gallery" [point]="point" [photo]="photo"></app-photo>
      </div>
    </div>
    <mat-divider *ngIf="!last"></mat-divider>
  </div>
</ng-container>

<ng-template #noPhotos><span class="no-photos">Galerie neobsahuje žádné fotografie</span></ng-template>

