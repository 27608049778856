import {AngularFirestoreDocument} from '@angular/fire/firestore';
import {User} from '../models/User';

export const isLoggedIn = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  return (user !== null && user.emailVerified !== false);
};
export const setUserData = (afs, user) => {
  const userRef: AngularFirestoreDocument<any> = afs.doc(`users/${user.uid}`);
  const userData: User = {
    uid: user.uid,
    email: user.email,
    displayName: user.displayName,
    photoURL: user.photoURL,
    emailVerified: user.emailVerified
  };
  return userRef.set(userData, {
    merge: true
  });
};
export const login = async (afAuth, email, password) => {
  return afAuth.signInWithEmailAndPassword(email, password);
};

export const logout = async (afAuth) => {
  return afAuth.signOut();
};

