import {Gallery} from './Gallery';
import * as firebase from 'firebase';
import {convertStringToGeoPoint} from '../utils/utils';
import {Photo} from './Photo';
import {ProblemStatus} from '../utils/constants';


export interface IMapPointData {
  id: string;
  userEmail?: string;
  featurePhotoUrl?: string;
  getFeaturePhotoUrl: () => Promise<string>;
  iconUrl: string;
  getGMapLatLng: () => google.maps.LatLng;
  filterKey: string;
  colorClass: string;
  displayTitle: string;
  displayKraj: string;
  displayStatus: string;
  galleries: Gallery[];
  kraj: string;
  mesto?: string;
  location: { geohash: string, geopoint: firebase.firestore.GeoPoint };
  status: number;
  title: string;
}

export abstract class BasePointData implements IMapPointData {
  tempLatLng?: string;
  userEmail?: string;
  featurePhotoUrl?: string;
  galleries: Gallery[];
  id: string;
  kraj: string;
  location: { geohash: string; geopoint: firebase.firestore.GeoPoint } = {
    geohash: '',
    geopoint: null
  };
  mesto: string;

  status: number;
  title: string;

  abstract colorClass: string;
  abstract filterKey: string;
  abstract displayTitle;
  abstract iconUrl: string;


  set displayStatus(status: string) {
  }

  get displayStatus(): string {
    const statusCircle = this.colorStatusCircle;
    if (this.status === ProblemStatus['Místo je zrealizováno']) {
      return statusCircle + 'Vyřešeno';
    } else {
      return statusCircle + ProblemStatus[this.status];
    }
  }

  get colorStatusCircle(): string {
    return `<span class='${this.colorClass} circle'></span>`;
  }

  get displayKraj(): string {
    return this.kraj ? this.kraj : 'Neznámý kraj';
  }

  get latlng(): string {
    if (this.location && this.location.geopoint) {
      return this.location.geopoint.latitude + ',' + this.location.geopoint.longitude;
    }
    return this.tempLatLng;

  }

  set latlng(text: string) {
    const geoPoint = convertStringToGeoPoint(text);
    if (geoPoint) {
      this.tempLatLng = '';
      this.location.geopoint = geoPoint;
    } else {
      console.error('unable to convert', text);
      this.location.geopoint = null;
      this.tempLatLng = text;
    }
  }

  protected constructor(attrs?: any) {
    Object.assign(this, attrs);
    this.galleries = [];
    if (attrs?.galleries?.length > 0) {
      this.galleries = attrs.galleries?.map(gallery => {
        gallery.photos = gallery.photos.map(photo => {
          return Object.assign(new Photo(), photo);
        });
        return gallery;
      });
    }
  }

  async getFeaturePhotoUrl(): Promise<string> {
    if (!this.featurePhotoUrl) {
      for (const gallery of this.galleries) {
        for (let photo of gallery.photos) {
          photo = Object.assign(new Photo(), photo);
          this.featurePhotoUrl = await photo.getUrlFor(this, gallery, true);
          if (this.featurePhotoUrl) {
            return this.featurePhotoUrl;
          }
        }
      }
    }
    if (!this.featurePhotoUrl) {
      return 'assets/no-photo.png';
    }
    return this.featurePhotoUrl;
  }

  getGMapLatLng(): google.maps.LatLng {
    return new google.maps.LatLng(this.location.geopoint?.latitude, this.location.geopoint?.longitude);
  }

  toJson(): object {
    const returnObject = JSON.parse(JSON.stringify(this));
    // preserve type of location
    returnObject.location = this.location;
    // remove bullshit from photos
    for (const gallery of returnObject.galleries) {
      for (const photo of gallery.photos) {
        delete photo.photosToUpload;
      }
    }
    return returnObject;
  }
}
