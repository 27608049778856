<a class="item">
  <div class="item-left" (click)="detailClicked.emit(mapPoint.data)">
    <div class="item-photo">
      <img [attr.src]="imgSrc" alt="Fotografie místa" />
    </div>
  </div>
  <div class="item-middle" (click)="detailClicked.emit(mapPoint.data)">
    <div class="item-heading">{{mapPoint.data.displayTitle}}</div>
    <div class="item-subheading">{{mapPoint.data.displayKraj}}</div>
    <div class="item-rating" innerHTML="{{mapPoint.data.displayStatus}}"></div>
  </div>
  <div class="item-right">
    <button
      mat-icon-button
      matTooltip="Pozice problému"
      (click)="positionClicked.emit(mapPoint.marker.getPosition())">
      <mat-icon>my_location</mat-icon>
    </button>
  </div>
</a>
