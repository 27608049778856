<h2 mat-dialog-title class="text-primary">
  {{problem.displayTitle}}
</h2>
<mat-dialog-content>
  <!--<pk-photo-detail #photoDetail [imageUrl]="imageUrl"></pk-photo-detail>-->
  <app-map-status [status]="problem.status"></app-map-status>

  <h2>Kraj</h2>
  <p>{{problem.kraj}}</p>

  <div *ngIf="problem.mesto">
    <h2>Město</h2>
    <p>{{problem.mesto}}</p>
  </div>

  <ng-container *ngIf="authService.currentUser$ | async as currentUser">
    <h2>Kontakt</h2>
    <mat-divider></mat-divider>
    <p>{{problem.userEmail ?  problem.userEmail : 'Vytvořeno administrátorem'}}</p>
  </ng-container>


  <h2>Nehody</h2>
  <mat-divider></mat-divider>
  <div *ngIf="problem.accidents" class="nehody">
    <app-stat [counter]="problem.accidents.hmotneSkody" icon="drive_eta" text="Hmotné škody"></app-stat>
    <app-stat [counter]="problem.accidents.lehkaZraneni" icon="elderly" text="Lehká zranění"></app-stat>
    <app-stat [counter]="problem.accidents.tezkaZraneni" icon="wheelchair_pickup" text="Těžká zranění"></app-stat>
    <app-stat [counter]="problem.accidents.umrti" icon="airline_seat_flat" text="Úmrtí"></app-stat>
  </div>

  <div *ngIf="problem.cost" class="cost">
    Celkové vyčíslení nehod: <b>{{problem.cost.cost | number}} Kč</b> (údaj z roku {{problem.cost.year}})
  </div>

  <h2>Popis místa</h2>
  <mat-divider></mat-divider>
  <p>{{problem.placeDescription}}</p>

  <h2>Popis rizika</h2>
  <mat-divider></mat-divider>
  <p>{{problem.riskDescription}}</p>
  <div *ngIf="problem.progress.length > 0">
    <h2>Vývoj místa</h2>
    <mat-divider></mat-divider>
    <div *ngFor="let prog of problem.progress;let last = last">
      <h3>{{prog.title}}</h3>
      <p>{{prog.description}}</p>
      <mat-divider *ngIf="!last"></mat-divider>
    </div>
  </div>
  <div *ngIf="problem.galleries.length > 0">
    <h2>Galerie místa</h2>
    <mat-divider></mat-divider>
    <app-photo-gallery [point]="problem"></app-photo-gallery>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button color="secondary" (click)="close()">
    Zavřít
  </button>
  <ng-container *ngIf="authService.currentUser$ | async as currentUser">
    <button mat-flat-button color="secondary" (click)="edit()">
      Upravit
    </button>
    <button mat-stroked-button color="accent" (click)="delete()">
      Smazat
    </button>
  </ng-container>
</mat-dialog-actions>


<ng-template #noPhotos><span class="no-photos">Galerie neobsahuje žádné fotografie</span></ng-template>
