import {Component, Input, OnInit} from '@angular/core';
import {Photo} from '../../models/Photo';
import {Gallery} from '../../models/Gallery';
import {IMapPointData} from "../../models/BasePointData";


@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss']
})
export class PhotoComponent implements OnInit {

  @Input()
  photo: Photo;

  @Input()
  point: IMapPointData;

  @Input()
  gallery: Gallery;

  imgSrc: string;
  fullImgSrc: string;

  constructor() { }

  async ngOnInit(): Promise<void> {
    this.imgSrc = await this.photo.getUrlFor(this.point, this.gallery, true);
    this.fullImgSrc = await this.photo.getUrlFor(this.point, this.gallery, false);
  }

}
