import {Component, Input} from '@angular/core';
import {getStatusesValues, ProblemStatus} from '../../utils/constants';

@Component({
  selector: 'app-map-status',
  templateUrl: './map-status.component.html',
  styleUrls: ['./map-status.component.scss']
})
export class MapStatusComponent {
  @Input()
  status: number;

  statuses;
  statusesValues: number[];

  constructor() {
    this.statuses = ProblemStatus;
    this.statusesValues = getStatusesValues();
  }

}
